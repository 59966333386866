import request from "@/utils/request";

const api = {
  downloadUrl: "/system/app-upgrade/download", // 获取不同手机类型下载App 的链接地址
  iosDownloadUrl: "/system/apple-business/redemption", // ios下载url 获取手机号码
};

/**
 * 下载
 * @param {*} parameter
 */
export function getDownloadUrl(parameter) {
  return request.get(api.downloadUrl, parameter);
}

/**
 * IOS下载
 * @param {*} parameter
 */
export function getDownloadUrlByPhone(parameter) {
  return request.get(api.iosDownloadUrl, parameter);
}
